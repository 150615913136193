export enum KioskMachineEvents {
  /** Add a coupon at the checkout */
  ADD_COUPON = 'ADD_COUPON',
  /** Leave the attract mode splash screen and begin a browsing flow session */
  START_SHOPPING = 'START_SHOPPING',
  /** After a period of inactivity, automatically show the user the StillThereModal asking if they want to continue their current shopping session or start a new one */
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  /** Fired when a user selects to continue their current shopping session from the StillThereModal triggered by the SESSION_TIMEOUT event */
  RESUME = 'RESUME',
  /** Returns the user to the attract mode/splash screen after the user has been shown the StillThereModal from the SESSION_TIMEOUT transition and either selected to have a new session or the session timed out and restarted fresh */
  TIMEOUT = 'TIMEOUT',
  /** Transition the user to the personalization flow from the browsing flow once the user has selected a card to personalize */
  PERSONALIZE_CARD = 'PERSONALIZE_CARD',
  /** Transition the user back to the browsing flow from the checkout flow cart page, after they have completed a personalization wanted to keep shopping */
  CONTINUE_SHOPPING = 'CONTINUE_SHOPPING',
  SAVE_CHECKOUT_ORDER_ID = 'SAVE_CHECKOUT_ORDER_ID',
  /** Transition the user back to the browsing flow from the personalization flow (and cancel any changes) */
  RETURN_TO_BROWSING = 'RETURN_TO_BROWSING',
  /** Transition the user back to the checkout flow from the personalization flow if they want to cancel any changes that they have made to a previously saved card */
  RETURN_TO_CHECKOUT = 'RETURN_TO_CHECKOUT',
  /** Transition the user to the checkout flow from the browsing flow if their cart is not empty */
  INITIATE_CHECKOUT = 'INITIATE_CHECKOUT',
  /** Skip the shopping session/browsing flow and allow the user to pick up an online order */
  PICKUP_ORDER = 'PICKUP_ORDER',
  /** Transition the user back to the browsing flow from the order pickup flow if they want to go back to shopping */
  CANCEL_ORDER_PICKUP = 'CANCEL_ORDER_PICKUP',
  /** Exits the personalization flow and returns the user to either the flow that they were in before (either browsing or checkout) */
  CANCEL_PERSONALIZATION = 'CANCEL_PERSONALIZATION',
  /** Return to the personalization flow to edit the selected CartItem */
  EDIT_CARD = 'EDIT_CARD',
  /** Add or subtract 1 from the selected CartItem's quantity */
  UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY',
  /** Trigger a setTimeout to delete the selected CartItem from the cart */
  MARK_CART_ITEM_FOR_DELETION = 'MARK_CART_ITEM_FOR_DELETION',
  /** Cancel the setTimeout meant do delete the selected CartItem from the cart if the timeout exists. */
  UNDO_MARK_CART_ITEM_FOR_DELETION = 'UNDO_MARK_CART_ITEM_FOR_DELETION',
  /** Immediately remove the selected CartItem from the cart, without a setTimeout */
  DELETE_CART_ITEM = 'DELETE_CART_ITEM',
  /** After we validate that the pickup code for mobile is linked to a valid order id, capture that order id from the order pickup flow */
  UPDATE_ORDER_ID = 'UPDATE_ORDER_ID',
  /** This triggers on initiation of rewards screen */
  INITIATE_REWARDS = 'INITIATE_REWARDS',
  /** This triggers when a user is logged in already and clicks the rewards button in the nav bar ---Shows their rewards */
  SHOW_USER_REWARDS = 'SHOW_USER_REWARDS',
  /** This triggers when no accounts were found */
  NO_ACCOUNT_FOUND = 'NO_ACCOUNT_FOUND',
  /** This exits the rewards screen and resets the state of the login modal */
  EXIT_REWARDS = 'EXIT_REWARDS',
  /** This exits rewards specifically from the Splash Screen */
  EXIT_SPLASHSCREEN_REWARDS = 'EXIT_SPLASHSCREEN_REWARDS',
  /** This represents a successful login, either by account creation or phone sign in */
  LOGIN_COMPLETE = 'LOGIN_COMPLETE',
  /** This represents a request for a ONE_TIME_PASS */
  ONE_TIME_PASS = 'ONE_TIME_PASS',
  /** This triggers for a customer logging out */
  USER_LOG_OUT = 'USER_LOG_OUT',
  /** Updates the total rewards for the main Kiosk Machine */
  UPDATE_TOTAL_REWARDS = 'UPDATE_TOTAL_REWARDS',
  /** Updates the RewardsCouponCode when a user chooses their reward */
  USER_CHOSE_REWARD = 'USER_CHOSE_REWARD',
  /** Updates users account details */
  UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS',
}

export enum BrowsingFlowEvents {
  /** Go back to the homepage after being on the category page, all categories page, in the SelectCardModal */
  VIEW_HOME = 'VIEW_HOME',
  /** Go to the all categories route */
  VIEW_ALL_CATEGORIES = 'VIEW_ALL_CATEGORIES',
  /** Go to the route for the specified category. Required a `categoryId` to be specified */
  VIEW_CATEGORY = 'VIEW_CATEGORY',
  /** Open the SelectCardModal where the user can confirm or cancel the card they just selected before personalizing */
  SELECT_CARD = 'SELECT_CARD',
  /** Leave the SelectCardModal and return to the previous state */
  CANCEL_SELECT_CARD = 'CANCEL_SELECT_CARD',
  /** Transition to the personalization flow after selecting a card */
  CONFIRM_SELECT_CARD = 'CONFIRM_SELECT_CARD',
  /** Triggered when the personalization flow handles the CANCEL_PERSONALIZATION event, a user has cancelled the personalization of the currently selected card */
  RETURN_TO_BROWSING_FLOW = 'RETURN_TO_BROWSING_FLOW',
}

export enum PersonalizationEvents {
  /** Move the machine forward one state (unless otherwise dictated by a guard) */
  CONTINUE = 'CONTINUE',
  /** Move the machine backward one step (unless otherwise dictated by a guard) */
  BACK = 'BACK',
  /** Exits the personalization flow and returns the user to either the flow that they were in before (either browsing or checkout) */
  CANCEL_PERSONALIZATION = 'CANCEL_PERSONALIZATION',
  /** Select a gift card to be customized; this will take the user to the gift card customization screen */
  CHOOSE_GIFT_CARD = 'CHOOSE_GIFT_CARD',
  /** Trigger the kiosk editing modal to allow the user to add/edit the email */
  EDIT_GIFT_CARD_EMAIL = 'EDIT_GIFT_CARD_EMAIL',
  /** Trigger the kiosk editing modal to allow the user to add/edit the price (this is only called if the gift card allows a range of inputs) */
  EDIT_GIFT_CARD_PRICE = 'EDIT_GIFT_CARD_PRICE',
  /** Mutate the machine context with the new gift card price (can be called from the editor modal, or directly in the gift card editing state). */
  UPDATE_GIFT_CARD_PRICE = 'UPDATE_GIFT_CARD_PRICE',
  /** Mutate the machine context with the new gift card price (can be called from the editor modal, or directly in the gift card editing state). If on mobile, this should be triggered on the `onBlur` event of the `<input>` as the focus is leaving the `<input>`. If on the kiosk, this should be triggered when closing the modal. */
  SAVE_GIFT_CARD_PRICE = 'SAVE_GIFT_CARD_PRICE',
  /** Mutate the machine context with the new email value */
  UPDATE_GIFT_CARD_EMAIL = 'UPDATE_GIFT_CARD_EMAIL',
  /** Mutate the machine context with the new email value. If on mobile, this should be triggered on the `onBlur` event of the `<input>` as the focus is leaving the `<input>`. If on the kiosk, this should be triggered when closing the modal. */
  SAVE_GIFT_CARD_EMAIL = 'SAVE_GIFT_CARD_EMAIL',
  /** Trigger kiosk editing modal to allow the user to add / edit the card message */
  EDIT_MESSAGE = 'EDIT_MESSAGE',
  /** Trigger kiosk editing modal to allow the user to add / edit the card signature */
  EDIT_SIGNATURE = 'EDIT_SIGNATURE',
  /** Trigger kiosk editing modal to allow the user to add / edit the card signature image*/
  EDIT_SIGNATURE_IMG = 'EDIT_SIGNATURE_IMG',
  /** Removes all gift card related context from the machine and pushes the user back to the gift card selection view */
  REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD',
  /** Mutate the machine context with the new message value */
  SAVE_MESSAGE = 'SAVE_MESSAGE',
  /** Mutate the machine context with the new signature value */
  SAVE_SIGNATURE = 'SAVE_SIGNATURE',
  SAVE_SIGNATURE_IMG = 'SAVE_SIGNATURE_IMG',
  CANCEL_SIGNATURE_IMG = 'CANCEL_SIGNATURE_IMG',
  /** Mutate the machine context with the new font value */
  UPDATE_FONT = 'UPDATE_FONT',
  UPDATE_TEXT_FORMAT = 'UPDATE_TEXT_FORMAT',
  UPDATE_TEXT_POSITION = 'UPDATE_TEXT_POSITION',
  /** Open the gift card selector modal, allowing the user to view more cards */
  VIEW_MORE = 'VIEW_MORE',
  TO_INTERIOR = 'TO_INTERIOR',
  TO_GIFT_CARDS = 'TO_GIFT_CARDS',
  TO_PREVIEW = 'TO_PREVIEW',
}

export enum CheckoutFlowEvents {
  /** Add a coupon collection area in the checkout flow */
  ADD_COUPON = 'ADD_COUPON',
  /** Payment has been successful and we need to transition to the fulfillment flow */
  COMPLETE_ORDER = 'COMPLETE_ORDER',
  /** The user has confirmed the amount and their card has been Approved by the POS terminal */
  CONFIRM_PAYMENT = 'CONFIRM_PAYMENT',
  /** Transition to the receipt collection screen in the Checkout flow */
  CONTINUE = 'CONTINUE',
  /** Return to the browsing flow to continue shopping for more cards */
  RETURN_TO_SHOPPING = 'RETURN_TO_SHOPPING',
  /** Immediately remove the selected CartItem from the cart, without a setTimeout */
  DELETE_CART_ITEM = 'DELETE_CART_ITEM',
  /** Return to the personalization flow to edit the selected CartItem */
  EDIT_CARD = 'EDIT_CARD',
  /** Trigger a setTimeout to delete the selected CartItem from the cart */
  MARK_CART_ITEM_FOR_DELETION = 'MARK_CART_ITEM_FOR_DELETION',
  /** Fired when the user manually triggers a payment cancellation or the developer needs to cancel the payment from somewhere within the checkout flow */
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  /** Fired when there is an issue with the payment succeeding */
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  /** Fired when the mzeroPaymentMachine completes successfully, indicating that the payment has been collected */
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  /** User is trying to pick up a mobile order and should move to the fulfillment flow */
  PICKUP_ORDER = 'PICKUP_ORDER',
  /** Return to the browsing flow homepage */
  RETURN_HOME = 'RETURN_HOME',
  /** Transitions the user back to the cart from the payment screen */
  RETURN_TO_CART = 'RETURN_TO_CART',
  /** Save a coupon  in the checkout flow */
  SAVE_COUPON = 'SAVE_COUPON',
  /** Add or subtract 1 from the selected CartItem's quantity */
  UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY',
  /** Cancel the setTimeout meant do delete the selected CartItem from the cart if the timeout exists. */
  UNDO_MARK_CART_ITEM_FOR_DELETION = 'UNDO_MARK_CART_ITEM_FOR_DELETION',
  /** Return to the browsing flow all categories page */
  VIEW_ALL_CATEGORIES = 'VIEW_ALL_CATEGORIES',
  /** Return to the browsing flow in a specific category page */
  VIEW_CATEGORY = 'VIEW_CATEGORY',
  /** Initiate Rewards in Cart-View */
  INITIATE_REWARDS_CARTVIEW = 'INITIATE_REWARDS_CARTVIEW',
  /** Exit Rewards Cartview */
  EXIT_REWARDS_CARTVIEW = 'EXIT_REWARDS_CARTVIEW',
  /** Login complete in Cartview */
  LOGIN_COMPLETE_CARTVIEW = 'LOGIN_COMPLETE_CARTVIEW',
  /** User logout in Cartview */
  USER_LOGOUT_CARTVIEW = 'USER_LOGOUT_CARTVIEW',
  /** No account found in Cartview */
  NO_ACCOUNT_FOUND_CARTVIEW = 'NO_ACCOUNT_FOUND_CARTVIEW',
  /** One time pass in Cartview */
  ONE_TIME_PASS_CARTVIEW = 'ONE_TIME_PASS_CARTVIEW',
  /** User chose Reward Cartview */
  USER_CHOSE_REWARD_CARTVIEW = 'USER_CHOSE_REWARD_CARTVIEW',
}

export enum FulfillmentFlowEvents {
  /** allow the user to change their selection (between print or digital receipt) */
  BACK = 'BACK',
  CONTINUE = 'CONTINUE',
  END_SESSION = 'END_SESSION',
  /** update the machine context to indicate that the user wants a printed receipt */
  SELECT_PAPER_RECEIPT = 'SELECT_PAPER_RECEIPT',
  /** any interaction with the kiosk will reset the 25sec timeout on the receipt collection component of the flow */
  RESET_TIMEOUT = 'RESET_TIMEOUT',
  /** update the machine context to indicate that the user wants a digital receipt */
  SELECT_DIGITAL_RECEIPT = 'SELECT_DIGITAL_RECEIPT',
  /** This action is taken when the user deliberately denies signing up for the newsletter */
  SKIP_NEWSLETTER = 'SKIP_NEWSLETTER',
  /** Save the user's email to the machine context */
  SUBMIT_EMAIL = 'SUBMIT_EMAIL',
  /** Call the mutation to subscribe the user to the Ink'd newsletter */
  SUBSCRIBE = 'SUBSCRIBE',
  /** if the user hasn't interacted with the kiosk for some duration, skip the email collection and proceed with the flow */
  TIMEOUT = 'TIMEOUT',
  DECREMENT_PRINTS_REMAINING = 'DECREMENT_PRINTS_REMAINING',
  SET_ADVERT_URL = 'SET_ADVERT_URL',
  SUBMIT_PHONE_NUMBER = 'SUBMIT_PHONE_NUMBER',
  SKIP_PHONE_NUMBER = 'SKIP_PHONE_NUMBER',
}

export enum MzeroPayEvents {
  /** This is called when the user has accepted the payment amount and presented their card to the POS */
  CONFIRM_PAYMENT = 'CONFIRM_PAYMENT',
  /** this will be the default case in the payment callback */
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  /** This is called when the payment is rejected by the user's bank */
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  /** This is called when the user fails to present a card in a timely manner */
  TIMEOUT = 'TIMEOUT',
}

export enum OrderPickupEvents {
  /** Transition the user back to the browsing flow */
  CANCEL_ORDER_PICKUP = 'CANCEL_ORDER_PICKUP',
  /** Trigger the kiosk editing modal to allow the user to add/edit the order pickup code */
  EDIT_PICKUP_CODE = 'EDIT_PICKUP_CODE',
  /** Mutate the machine context with the new pickup code */
  SAVE_PICKUP_CODE = 'SAVE_PICKUP_CODE',
  /** Fetch the order based upon the saved pickup code */
  SUBMIT_PICKUP_CODE = 'SUBMIT_PICKUP_CODE',
}
