import { createPortal } from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import { KIOSK_ASSET_BASE_PATH } from '@inkd/utils';
import {
  KioskEditorModal,
  renderWithCursor,
  PhoneNumberModal,
} from '../KioskEditorModal';
import 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './index.css';
import RewardList from './RewardList';

function formatPhoneNumber(phoneInput: string) {
  const match = phoneInput.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (!match) return phoneInput;

  let formatted = '';
  if (match[1]) {
    formatted += `(${match[1]}`;
  }
  if (match[1]?.length === 3) {
    formatted += `)`;
  }
  if (match[2]) {
    formatted += match[2];
  }
  if (match[2]?.length === 3) {
    formatted += `-`;
  }
  if (match[3]) {
    formatted += match[3];
  }
  return formatted;
}

const translations = {
  en: {
    signInPlaceholder: 'Enter your phone number',
    signInContinue: 'Continue',
    signInClose: 'Close',
    createAccountHeading: 'How would you like to receive your code?',
    sms: 'SMS',
    voiceCall: 'Voice Call',
    termsAndPrivacy:
      "By signing up, I accept the Ink'd Terms of Service and acknowledge the Privacy Policy.",
    createAccountClose: 'Close',
    oneTimePassHeading: 'Please enter the one-time pass sent to you!',
    oneTimePassPlaceholder: 'xxxx',
    oneTimePassClose: 'Close',
    oneTimePassSubmit: 'Submit',
    welcomeBack: 'Welcome Back!',
    rewardsHeading: 'Rewards',
    rewardsText: 'You have',
    rewardsSuffix: 'points!',
    marketingPrompt: 'Receive special offers and promotions!',
    logout: 'Log Out',
    confirm: 'Apply',
    selectReward: 'Select Your Reward:',
    rewardInfo1: 'Receive 100 points per $1 spent on greeting cards',
    rewardInfo2: 'Receive 10 points per $1 spent on gift cards',
    rewardInfo3: 'Free standard shipping online',
    rewardInfo4: 'No convenience fee on gift cards',
    applyReward: 'Applying Reward...',
    accountBenefits: 'Reward Benefits:',
    yourEarning: "You're earning points with every purchase!",
  },
  es: {
    signInPlaceholder: 'Ingresa tu número de teléfono',
    signInContinue: 'Continuar',
    signInClose: 'Cerrar',
    createAccountHeading: '¿Cómo te gustaría recibir tu código?',
    sms: 'SMS',
    voiceCall: 'Llamada de voz',
    termsAndPrivacy:
      "Al registrarme, acepto los Términos de Servicio de Ink'd y reconozco la Política de Privacidad.",
    createAccountClose: 'Cerrar',
    oneTimePassHeading:
      '¡Por favor, ingresa el código de un solo uso que te enviamos!',
    oneTimePassPlaceholder: 'xxxx',
    oneTimePassClose: 'Cerrar',
    oneTimePassSubmit: 'Enviar',
    welcomeBack: '¡Bienvenido de nuevo!',
    rewardsHeading: 'Recompensas',
    rewardsText: 'Tienes',
    rewardsSuffix: 'puntos!',
    marketingPrompt: '¡Recibe ofertas especiales y promociones!',
    logout: 'Cerrar sesión',
    confirm: 'Confirmar',
    selectReward: 'Selecciona Tu Recompensa:',
    rewardInfo1:
      'Recibe 100 puntos por cada $1 gastado en tarjetas de felicitación',
    rewardInfo2: 'Recibe 10 puntos por cada $1 gastado en tarjetas de regalo',
    rewardInfo3: 'Envío estándar gratis en línea',
    rewardInfo4: 'Sin tarifa de conveniencia en tarjetas de regalo',
    applyReward: 'Aplicando recompensa...',
    accountBenefits: 'Beneficios de las recompensas:',
    yourEarning: '¡Estás ganando puntos con cada compra!',
  },
};

export interface LoginModalProps {
  currentStep: number;
  phoneNumber: string;
  isSubscribed: boolean;
  localError?: string | null;
  otp: string | undefined;
  rewardPoints: number;
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onLogout: () => void;
  onConfirmReward: (selectedReward: string) => void;
  onPhoneNumberChange: (value: string) => void;
  onSignIn: (phone?: string) => void;
  onOTP: (method: string) => void;
  onValidateOTP: (otp?: string) => void;
  onOTPChange: (value: string) => void;
  onIsSubscribedChange: (value: boolean) => void;
  spanish: boolean;
}

const STEPS = {
  SIGN_IN: 0,
  CREATE_ACCOUNT: 1,
  ONE_TIME_PASS: 2,
  SIGNED_IN: 3,
  REWARDS_VIEW: 4,
  REWARD_SELECTED: 5,
};

const LoginModal: React.FC<LoginModalProps> = ({
  currentStep,
  phoneNumber,
  localError,
  otp,
  isSubscribed,
  rewardPoints,
  onLogout,
  onClose,
  onConfirmReward,
  onPhoneNumberChange,
  onSignIn,
  onOTP,
  onValidateOTP,
  onOTPChange,
  onIsSubscribedChange,
  spanish,
}) => {
  const t = spanish ? translations.es : translations.en;

  const [selectedReward, setSelectedReward] = useState<string | null>(null);
  const [kioskOpen, setKioskOpen] = useState(false);
  const [kioskInput, setKioskInput] = useState<string>('');
  const [cursorPointer, setCursorPointer] = useState<number>(0);
  const [charMap, setCharMap] = useState<Map<number, number>>(new Map());
  const kioskEditorRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef();
  const kioskKey = `kiosk-step-${currentStep}`;

  const [tempPhone, setTempPhone] = useState(phoneNumber);

  useEffect(() => {
    setTempPhone(phoneNumber);
  }, [phoneNumber]);

  const handleContinue = () => {
    const cleaned = tempPhone.replace(/\D/g, '');
    if (cleaned.length !== 10) {
      return;
    }
    onPhoneNumberChange(tempPhone);
    onSignIn(tempPhone);
  };

  return (
    <div className='fixed inset-0 z-30 flex flex-col items-center justify-center bg-black bg-opacity-50'>
      {currentStep === STEPS.SIGN_IN && (
        <>
          <div
            className={
              spanish
                ? 'relative h-auto w-4/5 rounded-lg bg-white p-12 shadow-lg'
                : 'relative h-[50%] w-4/5 rounded-lg bg-white p-12 shadow-lg'
            }
          >
            <img
              src={
                spanish
                  ? `${KIOSK_ASSET_BASE_PATH}/rewards-img-es.png`
                  : `${KIOSK_ASSET_BASE_PATH}/rewards-img.png`
              }
              alt='Rewards'
            />
            <input
              type='text'
              placeholder={t.signInPlaceholder}
              value={tempPhone}
              onClick={() => {
                setKioskInput(tempPhone);
                setCursorPointer(tempPhone.length);
                setKioskOpen(true);
              }}
              readOnly
              className='text-ui-sm mb-4 mt-5 h-20 w-full rounded-lg border border-gray-300 p-2 text-center'
            />

            <div className='mb-2 flex min-h-[50px] flex-col items-center justify-center space-y-2 text-center'>
              {localError && <p>{localError}</p>}
            </div>

            <div
              className={
                spanish
                  ? 'mt-8 flex items-center justify-center gap-80'
                  : 'mt-8 flex items-center justify-center gap-96'
              }
            >
              <button
                onClick={onClose}
                className='text-ui-md rounded-lg border-2 px-8 py-4'
              >
                {t.signInClose}
              </button>
              <button
                onClick={handleContinue}
                className='text-ui-md rounded-lg border-2 px-8 py-4'
              >
                {t.signInContinue}
              </button>
            </div>
          </div>
        </>
      )}
      {currentStep === STEPS.CREATE_ACCOUNT && (
        <>
          <div className='relative h-[34.5%] w-4/5 rounded-lg bg-white p-12 shadow-xl'>
            <div className='mx-auto my-auto flex flex-col items-center justify-center space-y-6 rounded-lg border-2 border-solid border-gray-300 p-10'>
              <p className='text-ui-lg px-6 pb-6 text-center font-bold text-gray-800'>
                {t.createAccountHeading}
              </p>

              <div className='flex gap-8 pb-4'>
                <button
                  onClick={() => onOTP('SMS')}
                  className='text-ui-md h-20 w-60 rounded-lg border-2 border-gray-400 px-6 py-3 transition-all duration-300 hover:border-black hover:bg-gray-100'
                >
                  {t.sms}
                </button>
                <button
                  onClick={() => onOTP('VOICE')}
                  className='text-ui-md h-20 w-auto rounded-lg border-2 border-gray-400 px-6 py-3 transition-all duration-300 hover:border-black hover:bg-gray-100 '
                >
                  {t.voiceCall}
                </button>
              </div>
              <div className='flex items-center gap-5'>
                <input
                  type='checkbox'
                  checked={isSubscribed}
                  onChange={e => onIsSubscribedChange(e.target.checked)}
                  className='h-10 w-10'
                />
                <label
                  htmlFor='marketingOptIn'
                  className='text-ui-sm text-gray-700'
                >
                  {t.marketingPrompt}
                </label>
              </div>

              <p className='text-ui-tiny py-2 pb-4 text-center text-gray-600'>
                {t.termsAndPrivacy}
              </p>
              <button
                onClick={onClose}
                className='text-ui-sm w-40 rounded-lg border-2 border-gray-400 px-6 py-3 transition-all duration-300 hover:border-black hover:bg-gray-100'
              >
                {t.createAccountClose}
              </button>
            </div>
          </div>
        </>
      )}

      {currentStep === STEPS.ONE_TIME_PASS && (
        <>
          <div
            className={
              spanish
                ? 'relative h-[32.5%] w-4/5 rounded-lg bg-white p-12 shadow-xl'
                : 'relative h-[30.5%] w-4/5 rounded-lg bg-white p-12 shadow-xl'
            }
          >
            <div className='mx-auto my-auto flex flex-col items-center justify-center space-y-8 rounded-lg border-2 border-solid border-gray-300 p-12'>
              <p className='text-ui-lg px-6 pb-6 text-center font-bold text-gray-800'>
                {t.oneTimePassHeading}
              </p>

              <input
                type='text'
                placeholder={t.oneTimePassPlaceholder}
                className='text-ui-lg h-24 w-[80%] rounded-lg border-2 border-gray-400 p-4 text-center text-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-500'
                readOnly
                value={otp}
                onClick={() => {
                  setKioskInput(otp ?? '');
                  setCursorPointer(otp?.length ?? 0);
                  setKioskOpen(true);
                }}
              />

              <div className='flex gap-16 pt-4'>
                <button
                  onClick={onClose}
                  className='text-ui-md h-20 w-60 rounded-lg border-2 border-gray-400 px-6 py-3 transition-all duration-300 hover:border-black hover:bg-gray-100'
                >
                  {t.oneTimePassClose}
                </button>
                <button
                  onClick={() => onValidateOTP()}
                  className='text-ui-md h-20 w-60 rounded-lg border-2 border-gray-400 px-6 py-3 transition-all duration-300 hover:border-black hover:bg-gray-100'
                >
                  {t.oneTimePassSubmit}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep === STEPS.SIGNED_IN && (
        <div className='animate-scaleTo100 flex h-[660px] w-[480px] flex-col items-center justify-center rounded-lg bg-white'>
          <p className='text-ui-lg pb-5 text-center font-black  '>
            {t.welcomeBack}
          </p>
          <p className='text-ui-sm pb-3 text-center'>{t.yourEarning}</p>
          <img
            className='h-96 w-96'
            src={`${KIOSK_ASSET_BASE_PATH}/Inkedrewards-01.png`}
            alt='Rewards'
          />
          <div className='loader mt-6'></div>
        </div>
      )}
      {currentStep === STEPS.REWARDS_VIEW && (
        <div
          className={
            spanish
              ? 'relative h-auto w-[75%]  rounded-lg bg-white p-8 text-center shadow-2xl'
              : 'relative h-auto w-[76%]  rounded-lg bg-white p-8 text-center shadow-2xl'
          }
        >
          <button
            onClick={onLogout}
            className='absolute right-5 top-5 rounded-md bg-red-500 px-4 py-2 transition-all hover:bg-red-600'
          >
            {t.logout}
          </button>

          <h1 className='text-ui-lg font-bold'>{t.rewardsHeading}</h1>
          <p className='text-ui-md pb-3 pt-2'>
            {t.rewardsText} <span className='font-bold'>{rewardPoints}</span>{' '}
            {t.rewardsSuffix}
          </p>

          <div className='mt-4 flex items-start'>
            <img
              className='h-96 w-96'
              src={`${KIOSK_ASSET_BASE_PATH}/Inkedrewards-01.png`}
              alt='Inkd rewards mascot logo'
            />
            <div className='tex ml-4 w-[480px] text-left'>
              <h2 className='text-ui-md mb-3 font-extrabold'>
                {t.accountBenefits}
              </h2>
              <ul
                className={
                  spanish
                    ? 'text-ui-tiny list-inside list-disc leading-6'
                    : 'text-ui-sm list-inside list-disc leading-6'
                }
              >
                <li className={spanish ? 'py-8 pb-10' : 'py-6 pb-6'}>
                  {t.rewardInfo1}
                </li>
                <li className={spanish ? 'pb-10' : 'pb-6'}>{t.rewardInfo2}</li>
                <li className={spanish ? 'pb-10' : 'pb-6'}>{t.rewardInfo3}</li>
              </ul>
            </div>
          </div>
          <p className='text-ui-sm pb-4 pt-4'>{t.selectReward}</p>
          <div className=' mx-10 mt-2 flex justify-center rounded-lg'>
            <RewardList
              rewardPoints={rewardPoints}
              selectedReward={selectedReward}
              setSelectedReward={setSelectedReward}
              spanish={spanish}
            />
          </div>

          <div
            className={
              spanish
                ? 'mt-12 flex justify-center gap-x-60'
                : 'mt-12 flex justify-center gap-x-32'
            }
          >
            <button
              onClick={onClose}
              className='text-ui-md border-tonal-60 w-44 rounded-lg border-2 py-4 text-center'
            >
              {t.signInClose}
            </button>
            <button
              onClick={() => {
                if (selectedReward) {
                  onConfirmReward(selectedReward);
                }
              }}
              className={
                spanish
                  ? 'text-ui-md bg-gradient-purple-to-yellow-clipped w-56 rounded-md border-[5px] border-transparent bg-origin-border py-4 text-center [background-clip:padding-box,border-box]'
                  : 'text-ui-md bg-gradient-purple-to-yellow-clipped w-44 rounded-md border-[5px] border-transparent bg-origin-border py-4 text-center [background-clip:padding-box,border-box]'
              }
              disabled={!selectedReward}
            >
              {t.confirm}
            </button>
          </div>
        </div>
      )}
      {currentStep === STEPS.REWARD_SELECTED && (
        <div className='animate-scaleTo100 flex h-48 w-96 flex-col items-center justify-center rounded-lg bg-white'>
          <p className='text-ui-md text-center'>{t.applyReward}</p>
          <div className='loader mt-6'></div>
        </div>
      )}

      {createPortal(
        kioskOpen &&
          (currentStep === STEPS.ONE_TIME_PASS ? (
            <KioskEditorModal
              key={kioskKey}
              open={kioskOpen}
              onClose={() => setKioskOpen(false)}
              inputRef={kioskEditorRef}
              keyboard={keyboardRef}
              valueToEdit={otp ?? ''}
              handleConfirm={(userInput: string) => {
                onOTPChange(userInput);
                if (userInput.length === 4) {
                  onValidateOTP(userInput);
                }
                setKioskOpen(false);
              }}
              setInput={setKioskInput}
              input={kioskInput}
              cursorPointer={cursorPointer}
              setCursorPointer={setCursorPointer}
              charMap={charMap}
              setCharMap={setCharMap}
              tablet={false}
              spanish={spanish}
              type='number'
            >
              <div className='p-be-12 w-[750px] scale-125 rounded-lg bg-white p-8'>
                <label className='text-primary'>
                  <p className='text-ui-sm text-tonal-60 mb-2 block'>
                    One-Time Pass
                  </p>
                  <div
                    className='display_container rounded-tiny bg-surface-primary placeholder:text-gray-dark p-i-8 p-b-6 border-tonal-60 peer min-h-[96px] w-full border-4 text-left text-sm text-inherit disabled:cursor-not-allowed disabled:opacity-75'
                    style={{ height: '60px' }}
                  >
                    <input
                      ref={kioskEditorRef}
                      type='text'
                      value={kioskInput}
                      readOnly
                      onClick={e =>
                        setCursorPointer(e.currentTarget.selectionStart ?? 0)
                      }
                      onKeyUp={e =>
                        setCursorPointer(e.currentTarget.selectionStart ?? 0)
                      }
                    />
                    <div className='character_container'>
                      {renderWithCursor({
                        value: kioskInput,
                        cursorPosition: cursorPointer,
                      })}
                    </div>
                  </div>
                </label>
              </div>
            </KioskEditorModal>
          ) : (
            <PhoneNumberModal
              key={kioskKey}
              open={kioskOpen}
              onClose={() => setKioskOpen(false)}
              inputRef={kioskEditorRef}
              keyboard={keyboardRef}
              handleConfirm={(userInput: string) => {
                const digitsOnly = userInput.replace(/\D/g, '');
                const formattedNumber = formatPhoneNumber(digitsOnly);
                setTempPhone(formattedNumber);
                onPhoneNumberChange(formattedNumber);
                if (digitsOnly.length === 10) {
                  onSignIn(formattedNumber);
                }
                setKioskOpen(false);
              }}
              setInput={setKioskInput}
              input={kioskInput}
              cursorPointer={cursorPointer}
              setCursorPointer={setCursorPointer}
            >
              <div className='p-be-12 w-[750px] scale-125 rounded-lg bg-white p-8'>
                <label className='text-primary'>
                  <p className='text-ui-sm text-tonal-60 mb-2 block'>
                    {spanish ? 'Número de teléfono' : 'Phone Number'}
                  </p>
                  <div
                    className='display_container rounded-tiny bg-surface-primary placeholder:text-gray-dark p-i-8 p-b-6 border-tonal-60 peer min-h-[96px] w-full border-4 text-left text-sm text-inherit disabled:cursor-not-allowed disabled:opacity-75'
                    style={{ height: '60px' }}
                  >
                    <input
                      ref={kioskEditorRef}
                      type='text'
                      value={kioskInput}
                      readOnly
                      onClick={e =>
                        setCursorPointer(e.currentTarget.selectionStart ?? 0)
                      }
                      onKeyUp={e =>
                        setCursorPointer(e.currentTarget.selectionStart ?? 0)
                      }
                    />
                    <div className='character_container'>
                      {renderWithCursor({
                        value: kioskInput,
                        cursorPosition: cursorPointer,
                      })}
                    </div>
                  </div>
                </label>
              </div>
            </PhoneNumberModal>
          )),
        document.body
      )}
    </div>
  );
};

export default LoginModal;
