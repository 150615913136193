import { graphql } from '../generated-gql';

export const queryCustomerAccount = graphql(`
  query CustomerAccount($input: ID!) {
    customerAccount(id: $input) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedEmail
      isSubscribedPhone
      rewardPoints
      Order {
        id
        totalPrice
        couponId
        status
        orderPlacedFrom
        orderStartedTimestamp
      }
    }
  }
`);
