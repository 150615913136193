import React from 'react';
import RewardItem from './RewardItem';
import { queryAccountCoupons } from '@inkd/graphql';
import { useQuery } from '@apollo/client';

interface Reward {
  id: string;
  name: string;
  cost: number;
}

interface RewardListProps {
  rewardPoints: number;
  selectedReward: string | null;
  setSelectedReward: (id: string) => void;
  spanish?: boolean;
}

const RewardList: React.FC<RewardListProps> = ({
  rewardPoints,
  selectedReward,
  setSelectedReward,
  spanish = false,
}) => {
  const { data: couponsData, loading, error } = useQuery(queryAccountCoupons);

  if (loading) return <div>Loading rewards...</div>;
  if (error) return <div>Error loading rewards</div>;

  const rewards: Reward[] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    couponsData?.accountCoupons?.map((coupon: any) => {
      const hasRegularDiscount = coupon.couponAmount > 0;
      const hasGiftCard = coupon.giftCardAmount > 0;
      let name = '';
      switch (coupon.id) {
        case 'FREE_CARD':
          name = spanish
            ? 'Tarjeta de felicitación gratis!'
            : 'Free Greeting Card!';
          break;
        case 'FREE_2CARDS':
          name = spanish
            ? '¡2 Tarjetas de felicitación gratis!'
            : '2 Free Greeting Cards!';
          break;
        case 'FREE_GIFT5':
          name = spanish
            ? '¡$5 de descuento en Tarjeta de regalo!'
            : '$5 off Gift Card!';
          break;
        default: //Will need to probably add a name field for coupons
          if (coupon.hasRegularDiscount && coupon.hasGiftCard) {
            name = spanish
              ? `Ahorra $${coupon.couponAmount} y Tarjeta de Regalo $${coupon.giftCardAmount}`
              : `Save $${coupon.couponAmount} & Gift Card $${coupon.giftCardAmount}`;
          } else if (hasGiftCard) {
            name = spanish
              ? `Tarjeta de Regalo $${coupon.giftCardAmount}`
              : `Gift Card $${coupon.giftCardAmount}`;
          } else if (hasRegularDiscount) {
            name = spanish
              ? `Ahorra $${coupon.couponAmount}`
              : `Save $${coupon.couponAmount}`;
          }
      }
      return {
        id: coupon.id,
        name,
        cost: coupon.rewardsPointsCost,
      };
    }) || [];

  // Sort rewards in ascending order by cost (lowest cost first)
  rewards.sort((a, b) => a.cost - b.cost);

  return (
    <div className='flex flex-col gap-4'>
      {rewards.map(reward => (
        <RewardItem
          key={reward.id}
          name={reward.name}
          cost={reward.cost}
          userRewardPoints={rewardPoints}
          isSelected={selectedReward === reward.id}
          onSelect={() => setSelectedReward(reward.id)}
          spanish={spanish}
        />
      ))}
    </div>
  );
};

export default RewardList;
