import { graphql } from '../generated-gql';

export const mutationCustomerAccountUpdate = graphql(`
  mutation CustomerAccountUpdate($id: ID!, $edits: EditCustomerAccountInput!) {
    customerAccountUpdate(id: $id, edits: $edits) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedEmail
      isSubscribedPhone
      rewardPoints
    }
  }
`);
