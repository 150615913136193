import { graphql } from '../generated-gql';

export const queryCustomerAccountByEmail = graphql(`
  query CustomerAccountByEmail($email: String!) {
    customerAccountByEmail(email: $email) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedEmail
      isSubscribedPhone
      isCognitoVerified
      rewardPoints
    }
  }
`);
