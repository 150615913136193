import { graphql } from '../generated-gql';

export const mutationIncrementStatisticGiftCardRemovedFromOrder = graphql(`
  mutation IncrementStatisticGiftCardRemovedFromOrder {
    incrementStatistic(key: "giftCardRemovedFromOrder") {
      key
      value
    }
  }
`);

export const mutationIncrementStatisticStillThereIdleTimeout = graphql(`
  mutation IncrementStatisticStillThereIdleTimeout {
    incrementStatistic(key: "stillThereIdleTimeout") {
      key
      value
    }
  }
`);

export const mutationIncrementStatisticGreetingCardRemovedFromCart = graphql(`
  mutation IncrementStatisticGreetingCardRemovedFromCart {
    incrementStatistic(key: "greetingCardRemovedFromCart") {
      key
      value
    }
  }
`);
