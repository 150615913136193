import { graphql } from '../generated-gql';

export const queryStatistics = graphql(`
  query Statistics {
    statistics {
      id
      key
      createdAt
      updatedAt
      value
    }
  }
`);
