import { createPortal } from 'react-dom';
import { cx } from 'cva';
import { useModal } from '@inkd/ui';

export interface CADConfirmationModalProps {
  handleConfirm: () => void;
  handleCancel: () => void;
  spanish: boolean;
  version: 'web' | 'kiosk';
}

export function CADConfirmationModal({
  handleConfirm,
  handleCancel,
  spanish,
  version,
}: CADConfirmationModalProps) {
  const { Modal } = useModal();

  return createPortal(
    <Modal
      open
      id='cad-confirmation-modal'
      className={cx({
        'backdrop:bg-gradient-blue-to-yellow fixed inset-0 z-50 backdrop:z-50 backdrop:opacity-90':
          true,

        'w-[90%]': version === 'kiosk',

        'w-[40%]': version === 'web',
      })}
      headingText={spanish ? 'Advertencia' : 'Warning'}
      handleCancel={handleCancel}
      handleConfirm={(e, closeModal) => {
        e.preventDefault();
        closeModal();
        handleConfirm();
      }}
      cancelButtonText={spanish ? 'No, cancelar' : 'No, cancel'}
      confirmButtonText={spanish ? 'Sí, continuar' : 'Yes, continue'}
      closeButtonHandler='handleCancel'
    >
      <p className='text-body text-center'>
        {spanish
          ? 'Cards After Dark presenta contenido destinado exclusivamente para adultos. Se recomienda discreción del espectador.'
          : 'Cards After Dark features content intended exclusively for adult audiences. Continue?'}
      </p>
    </Modal>,
    document.body
  );
}

export default CADConfirmationModal;
