import React from 'react';
import type { ComponentPropsWithoutRef, RefObject, ReactNode } from 'react';
import Keyboard from 'react-simple-keyboard';
import { cx } from 'cva';
import 'react-simple-keyboard/build/css/index.css';
import './index.css';

export interface PhoneNumberModalProps
  extends ComponentPropsWithoutRef<'dialog'> {
  backgroundClassName?: string;
  inputRef: RefObject<HTMLInputElement>;
  keyboard: React.MutableRefObject<undefined>;
  handleConfirm: (userInput: string) => void;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  input: string;
  children?: ReactNode;
  cursorPointer?: number;
  setCursorPointer?: React.Dispatch<React.SetStateAction<number>>;
}

function formatPhoneNumber(value: string): string {
  const match = value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (!match) return value;
  let formatted = '';
  if (match[1]) {
    formatted += `(${match[1]}`;
  }
  if (match[1]?.length === 3) {
    formatted += `)`;
  }
  if (match[2]) {
    formatted += match[2];
  }
  if (match[2]?.length === 3) {
    formatted += `-`;
  }
  if (match[3]) {
    formatted += match[3];
  }
  return formatted;
}

const numericLayout = ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {confirm}'];

const display = {
  '{confirm}': 'Confirm',
  '{bksp}': '⌫',
};

enum kbNamedKey {
  CONFIRM = '{confirm}',
  BACKSPACE = '{bksp}',
}

export const PhoneNumberModal: React.FC<PhoneNumberModalProps> = ({
  backgroundClassName,
  inputRef,
  keyboard,
  handleConfirm,
  setInput,
  input,
  children,
  cursorPointer,
  setCursorPointer,
  ...dialogProps
}) => {
  const onKeyPress = (button: string) => {
    let rawDigits = input.replace(/\D/g, '');
    if (button === kbNamedKey.CONFIRM) {
      setTimeout(() => {
        handleConfirm(input);
      }, 300);
      return;
    } else if (button === kbNamedKey.BACKSPACE) {
      rawDigits = rawDigits.slice(0, -1);
    } else if (/^\d$/.test(button)) {
      if (rawDigits.length < 10) {
        rawDigits += button;
      }
    }
    const formatted = formatPhoneNumber(rawDigits);
    setInput(formatted);
    if (setCursorPointer) {
      setCursorPointer(formatted.length);
    }
  };

  const staticKeyboardProps = {
    disableButtonHold: true,
    useButtonTag: true,
    layout: {
      numeric: numericLayout,
    },
    mergeDisplay: true,
    display,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onInit: (kb: any) => {
      if (kb) {
        kb.setInput(input);
      }
    },
  };

  return (
    <dialog
      className={cx(
        'text-primary animate-scaleTo100 absolute top-0 z-30 h-full w-full max-w-[100vw] bg-transparent p-0'
      )}
      {...dialogProps}
    >
      <div
        className={
          backgroundClassName ??
          'bg-gradient-blue-to-purple absolute left-0 top-0 h-full w-full opacity-90'
        }
      />
      <div className='relative flex h-full w-full flex-col items-center justify-center gap-10'>
        <div className='z-10 flex w-[90%] max-w-[88%] flex-col items-center justify-center'>
          {children || (
            <input
              ref={inputRef}
              value={input}
              readOnly
              className='bg-surface-primary rounded p-2 text-center text-xl'
              placeholder='(xxx)xxx-xxxx'
            />
          )}
        </div>
        <div className='shadow-far2 z-20 flex h-fit w-[94%] flex-col items-center gap-8'>
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            onKeyPress={onKeyPress}
            layoutName='numeric'
            {...staticKeyboardProps}
          />
        </div>
      </div>
    </dialog>
  );
};
