import { graphql } from '../generated-gql/gql';

export const queryGiftCard = graphql(`
  query GiftCard($giftCardId: String!) {
    giftCard(id: $giftCardId) {
      denominationType
      id
      isActive
      name
      denominations
      faceplatePath
      favoriteRank
      provider
      cardMaxRating
      categories {
        id
        categoryName
      }
    }
  }
`);
