export const EyeIcon = () => (
  <svg
    className='h-6 w-6'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3.98 8.223c1.807-2.834 4.263-4.223 8.02-4.223s6.213 1.389 8.02 4.223c.393.616.393 1.422 0 2.038-1.807 2.834-4.263 4.223-8.02 4.223s-6.213-1.389-8.02-4.223a1.422 1.422 0 010-2.038z'
    />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
    />
  </svg>
);

export const EyeSlashIcon = () => (
  <svg
    className='h-6 w-6'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3.98 8.223c1.807-2.834 4.263-4.223 8.02-4.223 1.513 0 2.862.26 3.992.76M20.02 8.223c.393.616.393 1.422 0 2.038-.753 1.183-1.625 2.105-2.636 2.78M2.25 2.25l19.5 19.5M9.88 9.88A3 3 0 0115 12m-3-3c.754 0 1.444.282 1.98.74m-3.17 5.152A3 3 0 019 12c0-.308.04-.606.115-.887'
    />
  </svg>
);
