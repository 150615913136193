import { graphql } from '../generated-gql';

export const queryCustomerAccounts = graphql(`
  query CustomerAccounts($pagination: CustomerAccountInput) {
    customerAccounts(pagination: $pagination) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedEmail
      isSubscribedPhone
      rewardPoints
    }
  }
`);
