import { graphql } from '../generated-gql';

export const queryCustomerAccountByPhoneNumber = graphql(`
  query CustomerAccountByPhoneNumber($phoneNumber: String!) {
    customerAccountByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedPhone
      isSubscribedEmail
      isCognitoVerified
      isKioskVerified
      rewardPoints
    }
  }
`);
