import React from 'react';

interface RewardItemProps {
  name: string;
  cost: number;
  userRewardPoints: number;
  isSelected: boolean;
  onSelect: () => void;
  spanish?: boolean;
  customWidth?: string;
}

const RewardItem: React.FC<RewardItemProps> = ({
  name,
  cost,
  userRewardPoints,
  isSelected,
  onSelect,
  spanish = false,
}) => {
  const canRedeem = userRewardPoints >= cost;

  const customWidth = spanish ? 'w-[550px]' : 'w-[450px]';

  return (
    <button
      className={`bg-gradient-purple-to-yellow-clipped relative flex ${customWidth} flex-col items-center justify-center rounded-md border-[4px] py-2 shadow-lg transition-all duration-200
    ease-in-out
    ${
      isSelected
        ? 'border-transparent bg-origin-border [background-clip:padding-box,border-box]'
        : 'border-transparent'
    }
    ${!canRedeem && 'cursor-not-allowed'}
  `}
      onClick={() => canRedeem && onSelect()}
      disabled={!canRedeem}
    >
      <div
        className={`absolute inset-0 rounded-md border-[2px] ${
          isSelected ? 'border-transparent' : 'border-tonal-60'
        }`}
      >
        {isSelected && <span className='text-lg font-bold'></span>}
      </div>
      <p className='text-ui-sm text-center font-semibold'>{name}</p>
      <p className='text-ui-tiny mt-2 text-center'>
        {spanish ? 'Costo:' : 'Cost:'} <span className='font-bold'>{cost}</span>{' '}
        {spanish ? 'puntos' : 'points'}
      </p>
    </button>
  );
};

export default RewardItem;
