import { graphql } from '../generated-gql';

export const mutationCustomerAccountCreate = graphql(`
  mutation CustomerAccountCreate($input: CreateCustomerAccountInput!) {
    customerAccountCreate(input: $input) {
      id
      createdAt
      updatedAt
      email
      phoneNumber
      firstName
      lastName
      isSubscribedEmail
      isSubscribedPhone
      rewardPoints
    }
  }
`);
