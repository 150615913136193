/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n  query AccountCoupons {\n    accountCoupons {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": typeof types.AccountCouponsDocument,
    "\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n": typeof types.AdvertCreateDocument,
    "\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n": typeof types.AdvertDeleteDocument,
    "\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n": typeof types.AdvertUpdateDocument,
    "\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n": typeof types.CardCreateDocument,
    "\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n": typeof types.CardDeleteDocument,
    "\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n": typeof types.CardImagesUploadUrlDocument,
    "\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n": typeof types.CardUpdateDocument,
    "\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": typeof types.AdminCardsDocument,
    "\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": typeof types.AdminCardsByCategoriesDocument,
    "\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": typeof types.CategoryCreateDocument,
    "\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n": typeof types.CategoryDeleteDocument,
    "\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": typeof types.CategoryUpdateDocument,
    "\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n": typeof types.CouponCreateDocument,
    "\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n": typeof types.CouponDeleteDocument,
    "\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n": typeof types.CouponUpdateDocument,
    "\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n": typeof types.AdminCreateDocument,
    "\n  mutation GiftCardVendorCreate($input: GiftCardVendorCreateInput!) {\n    giftCardVendorCreate(input: $input) {\n      id\n    }\n  }\n": typeof types.GiftCardVendorCreateDocument,
    "\n  mutation GiftCardVendorDelete($giftCardVendorDeleteId: String!) {\n    giftCardVendorDelete(id: $giftCardVendorDeleteId) {\n      id\n    }\n  }\n": typeof types.GiftCardVendorDeleteDocument,
    "\n  mutation GiftCardVendorUpdate($input: GiftCardVendorUpdateInput!) {\n    giftCardVendorUpdate(input: $input) {\n      id\n    }\n  }\n": typeof types.GiftCardVendorUpdateDocument,
    "\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n": typeof types.KioskCreateDocument,
    "\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n": typeof types.KioskUpdateDocument,
    "\n  mutation KioskUpdateAll($input: KioskUpdateAllInput!) {\n    kioskUpdateAll(input: $input)\n  }\n": typeof types.KioskUpdateAllDocument,
    "\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n": typeof types.AdminLoginDocument,
    "\n  mutation OrderRefreshPickup($orderRefreshPickupId: ID!) {\n    orderRefreshPickup(id: $orderRefreshPickupId) {\n      id\n    }\n  }\n": typeof types.OrderRefreshPickupDocument,
    "\n  mutation AdminUserWhitelistCreate($input: AdminUserWhitelistCreateInput!) {\n    adminUserWhitelistCreate(input: $input) {\n      email\n    }\n  }\n": typeof types.AdminUserWhitelistCreateDocument,
    "\n  mutation AdminUserWhitelistDelete($email: String!) {\n    adminUserWhitelistDelete(email: $email) {\n      email\n    }\n  }\n": typeof types.AdminUserWhitelistDeleteDocument,
    "\n  query AdminUserWhitelists {\n    adminUserWhitelists {\n      email\n      role\n      isRegistered\n    }\n  }\n": typeof types.AdminUserWhitelistsDocument,
    "\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": typeof types.AdvertDocument,
    "\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": typeof types.AdvertByKioskAndCategoriesDocument,
    "\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": typeof types.AdvertsDocument,
    "\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": typeof types.AdvertsByKioskAndCategoriesDocument,
    "\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n": typeof types.CategoriesDocument,
    "\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n": typeof types.CategoriesWithRatingFilterDocument,
    "\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n": typeof types.AdminCategoriesDocument,
    "\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n": typeof types.AddonCategoriesDocument,
    "\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n": typeof types.CardDocument,
    "\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n": typeof types.CardsByCategoriesDocument,
    "\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n": typeof types.CategoryDocument,
    "\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": typeof types.CouponDocument,
    "\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n": typeof types.CouponRedemptionsIncrementDocument,
    "\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": typeof types.CouponsDocument,
    "\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n": typeof types.OrderCreateDocument,
    "\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n": typeof types.OrderReceiptCreateDocument,
    "\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n": typeof types.PersonalizedCardCreateDocument,
    "\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n": typeof types.PersonalizedCardsCreateDocument,
    "\n  query CustomerAccount($input: ID!) {\n    customerAccount(id: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n      Order {\n        id\n        totalPrice\n        couponId\n        status\n        orderPlacedFrom\n        orderStartedTimestamp\n      }\n    }\n  }\n": typeof types.CustomerAccountDocument,
    "\n  mutation CustomerAccountAwardPoints($input: AwardPointsInput!) {\n    customerAccountAwardPoints(input: $input) {\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountAwardPointsDocument,
    "\n  query CustomerAccountByEmail($email: String!) {\n    customerAccountByEmail(email: $email) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      isCognitoVerified\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountByEmailDocument,
    "\n  query CustomerAccountByPhoneNumber($phoneNumber: String!) {\n    customerAccountByPhoneNumber(phoneNumber: $phoneNumber) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedPhone\n      isSubscribedEmail\n      isCognitoVerified\n      isKioskVerified\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountByPhoneNumberDocument,
    "\n  mutation CustomerAccountCreate($input: CreateCustomerAccountInput!) {\n    customerAccountCreate(input: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountCreateDocument,
    "\n  mutation customerAccountDeactivate($input: CustomerAccountDeactivateInput!) {\n    customerAccountDeactivate(input: $input) {\n      id\n    }\n  }\n": typeof types.CustomerAccountDeactivateDocument,
    "\n  mutation CustomerAccountRedeemPoints($input: RedeemPointsInput!) {\n    customerAccountRedeemPoints(input: $input) {\n      id\n    }\n  }\n": typeof types.CustomerAccountRedeemPointsDocument,
    "\n  mutation CustomerAccountUpdate($id: ID!, $edits: EditCustomerAccountInput!) {\n    customerAccountUpdate(id: $id, edits: $edits) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountUpdateDocument,
    "\n  query CustomerAccounts($pagination: CustomerAccountInput) {\n    customerAccounts(pagination: $pagination) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": typeof types.CustomerAccountsDocument,
    "\n  query CustomerCount {\n    customerCount\n  }\n": typeof types.CustomerCountDocument,
    "\n  mutation CustomerLogin($input: CustomerLoginInput!) {\n    customerLogin(input: $input) {\n      customer {\n        phoneNumber\n        email\n        firstName\n        id\n        isSubscribedEmail\n        isSubscribedPhone\n        isCognitoVerified\n        lastName\n        updatedAt\n        rewardPoints\n        createdAt\n      }\n    }\n  }\n": typeof types.CustomerLoginDocument,
    "\n  query ReturningCustomerCount {\n    returningCustomerCount\n  }\n": typeof types.ReturningCustomerCountDocument,
    "\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n": typeof types.OrderForPickupDocument,
    "\n  query GiftCard($giftCardId: String!) {\n    giftCard(id: $giftCardId) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n": typeof types.GiftCardDocument,
    "\n  query GiftCardCategories {\n    giftCardCategories {\n      id\n      categoryNameSpanish\n      categoryName\n    }\n  }\n": typeof types.GiftCardCategoriesDocument,
    "\n  query GiftCards($input: GiftCardsInput!) {\n    giftCards(input: $input) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n": typeof types.GiftCardsDocument,
    "\n  mutation IncrementStatisticGiftCardRemovedFromOrder {\n    incrementStatistic(key: \"giftCardRemovedFromOrder\") {\n      key\n      value\n    }\n  }\n": typeof types.IncrementStatisticGiftCardRemovedFromOrderDocument,
    "\n  mutation IncrementStatisticStillThereIdleTimeout {\n    incrementStatistic(key: \"stillThereIdleTimeout\") {\n      key\n      value\n    }\n  }\n": typeof types.IncrementStatisticStillThereIdleTimeoutDocument,
    "\n  mutation IncrementStatisticGreetingCardRemovedFromCart {\n    incrementStatistic(key: \"greetingCardRemovedFromCart\") {\n      key\n      value\n    }\n  }\n": typeof types.IncrementStatisticGreetingCardRemovedFromCartDocument,
    "\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      autoCoupon\n      cardMaxRating\n      productId\n      disableGiftCards\n      giftCardBlacklist\n    }\n  }\n": typeof types.KioskDocument,
    "\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      disableGiftCards\n      autoCoupon\n      giftCardBlacklist\n    }\n  }\n": typeof types.KiosksDocument,
    "\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": typeof types.MobileOrderPickupCodeDocument,
    "\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": typeof types.AdminOrderDetailsDocument,
    "\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      chargeCaptured\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      selfOrder\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      paymentIntentId\n      status\n      shippingName\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": typeof types.OrderDetailsDocument,
    "\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n": typeof types.OrderMobilePickupDocument,
    "\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n      couponId\n      customerAccountId\n    }\n  }\n": typeof types.OrdersDocument,
    "\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n": typeof types.CardPrintQualityImageDocument,
    "\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      selfOrder\n      shippingName\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n": typeof types.QueryShippingOrdersDocument,
    "\n  query Statistics {\n    statistics {\n      id\n      key\n      createdAt\n      updatedAt\n      value\n    }\n  }\n": typeof types.StatisticsDocument,
    "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": typeof types.OrderUpdateDocument,
    "\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n": typeof types.UserSubscribeNewsletterDocument,
};
const documents: Documents = {
    "\n  query AccountCoupons {\n    accountCoupons {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": types.AccountCouponsDocument,
    "\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n": types.AdvertCreateDocument,
    "\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n": types.AdvertDeleteDocument,
    "\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n": types.AdvertUpdateDocument,
    "\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n": types.CardCreateDocument,
    "\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n": types.CardDeleteDocument,
    "\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n": types.CardImagesUploadUrlDocument,
    "\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n": types.CardUpdateDocument,
    "\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": types.AdminCardsDocument,
    "\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n": types.AdminCardsByCategoriesDocument,
    "\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryCreateDocument,
    "\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryDeleteDocument,
    "\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n": types.CategoryUpdateDocument,
    "\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n": types.CouponCreateDocument,
    "\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n": types.CouponDeleteDocument,
    "\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n": types.CouponUpdateDocument,
    "\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n": types.AdminCreateDocument,
    "\n  mutation GiftCardVendorCreate($input: GiftCardVendorCreateInput!) {\n    giftCardVendorCreate(input: $input) {\n      id\n    }\n  }\n": types.GiftCardVendorCreateDocument,
    "\n  mutation GiftCardVendorDelete($giftCardVendorDeleteId: String!) {\n    giftCardVendorDelete(id: $giftCardVendorDeleteId) {\n      id\n    }\n  }\n": types.GiftCardVendorDeleteDocument,
    "\n  mutation GiftCardVendorUpdate($input: GiftCardVendorUpdateInput!) {\n    giftCardVendorUpdate(input: $input) {\n      id\n    }\n  }\n": types.GiftCardVendorUpdateDocument,
    "\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n": types.KioskCreateDocument,
    "\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n": types.KioskUpdateDocument,
    "\n  mutation KioskUpdateAll($input: KioskUpdateAllInput!) {\n    kioskUpdateAll(input: $input)\n  }\n": types.KioskUpdateAllDocument,
    "\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n": types.AdminLoginDocument,
    "\n  mutation OrderRefreshPickup($orderRefreshPickupId: ID!) {\n    orderRefreshPickup(id: $orderRefreshPickupId) {\n      id\n    }\n  }\n": types.OrderRefreshPickupDocument,
    "\n  mutation AdminUserWhitelistCreate($input: AdminUserWhitelistCreateInput!) {\n    adminUserWhitelistCreate(input: $input) {\n      email\n    }\n  }\n": types.AdminUserWhitelistCreateDocument,
    "\n  mutation AdminUserWhitelistDelete($email: String!) {\n    adminUserWhitelistDelete(email: $email) {\n      email\n    }\n  }\n": types.AdminUserWhitelistDeleteDocument,
    "\n  query AdminUserWhitelists {\n    adminUserWhitelists {\n      email\n      role\n      isRegistered\n    }\n  }\n": types.AdminUserWhitelistsDocument,
    "\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": types.AdvertDocument,
    "\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n": types.AdvertByKioskAndCategoriesDocument,
    "\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": types.AdvertsDocument,
    "\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n": types.AdvertsByKioskAndCategoriesDocument,
    "\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n": types.CategoriesDocument,
    "\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n": types.CategoriesWithRatingFilterDocument,
    "\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n": types.AdminCategoriesDocument,
    "\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n": types.AddonCategoriesDocument,
    "\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n": types.CardDocument,
    "\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n": types.CardsByCategoriesDocument,
    "\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n": types.CategoryDocument,
    "\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": types.CouponDocument,
    "\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n": types.CouponRedemptionsIncrementDocument,
    "\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n": types.CouponsDocument,
    "\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n": types.OrderCreateDocument,
    "\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n": types.OrderReceiptCreateDocument,
    "\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n": types.PersonalizedCardCreateDocument,
    "\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n": types.PersonalizedCardsCreateDocument,
    "\n  query CustomerAccount($input: ID!) {\n    customerAccount(id: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n      Order {\n        id\n        totalPrice\n        couponId\n        status\n        orderPlacedFrom\n        orderStartedTimestamp\n      }\n    }\n  }\n": types.CustomerAccountDocument,
    "\n  mutation CustomerAccountAwardPoints($input: AwardPointsInput!) {\n    customerAccountAwardPoints(input: $input) {\n      rewardPoints\n    }\n  }\n": types.CustomerAccountAwardPointsDocument,
    "\n  query CustomerAccountByEmail($email: String!) {\n    customerAccountByEmail(email: $email) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      isCognitoVerified\n      rewardPoints\n    }\n  }\n": types.CustomerAccountByEmailDocument,
    "\n  query CustomerAccountByPhoneNumber($phoneNumber: String!) {\n    customerAccountByPhoneNumber(phoneNumber: $phoneNumber) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedPhone\n      isSubscribedEmail\n      isCognitoVerified\n      isKioskVerified\n      rewardPoints\n    }\n  }\n": types.CustomerAccountByPhoneNumberDocument,
    "\n  mutation CustomerAccountCreate($input: CreateCustomerAccountInput!) {\n    customerAccountCreate(input: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": types.CustomerAccountCreateDocument,
    "\n  mutation customerAccountDeactivate($input: CustomerAccountDeactivateInput!) {\n    customerAccountDeactivate(input: $input) {\n      id\n    }\n  }\n": types.CustomerAccountDeactivateDocument,
    "\n  mutation CustomerAccountRedeemPoints($input: RedeemPointsInput!) {\n    customerAccountRedeemPoints(input: $input) {\n      id\n    }\n  }\n": types.CustomerAccountRedeemPointsDocument,
    "\n  mutation CustomerAccountUpdate($id: ID!, $edits: EditCustomerAccountInput!) {\n    customerAccountUpdate(id: $id, edits: $edits) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": types.CustomerAccountUpdateDocument,
    "\n  query CustomerAccounts($pagination: CustomerAccountInput) {\n    customerAccounts(pagination: $pagination) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n": types.CustomerAccountsDocument,
    "\n  query CustomerCount {\n    customerCount\n  }\n": types.CustomerCountDocument,
    "\n  mutation CustomerLogin($input: CustomerLoginInput!) {\n    customerLogin(input: $input) {\n      customer {\n        phoneNumber\n        email\n        firstName\n        id\n        isSubscribedEmail\n        isSubscribedPhone\n        isCognitoVerified\n        lastName\n        updatedAt\n        rewardPoints\n        createdAt\n      }\n    }\n  }\n": types.CustomerLoginDocument,
    "\n  query ReturningCustomerCount {\n    returningCustomerCount\n  }\n": types.ReturningCustomerCountDocument,
    "\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n": types.OrderForPickupDocument,
    "\n  query GiftCard($giftCardId: String!) {\n    giftCard(id: $giftCardId) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n": types.GiftCardDocument,
    "\n  query GiftCardCategories {\n    giftCardCategories {\n      id\n      categoryNameSpanish\n      categoryName\n    }\n  }\n": types.GiftCardCategoriesDocument,
    "\n  query GiftCards($input: GiftCardsInput!) {\n    giftCards(input: $input) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n": types.GiftCardsDocument,
    "\n  mutation IncrementStatisticGiftCardRemovedFromOrder {\n    incrementStatistic(key: \"giftCardRemovedFromOrder\") {\n      key\n      value\n    }\n  }\n": types.IncrementStatisticGiftCardRemovedFromOrderDocument,
    "\n  mutation IncrementStatisticStillThereIdleTimeout {\n    incrementStatistic(key: \"stillThereIdleTimeout\") {\n      key\n      value\n    }\n  }\n": types.IncrementStatisticStillThereIdleTimeoutDocument,
    "\n  mutation IncrementStatisticGreetingCardRemovedFromCart {\n    incrementStatistic(key: \"greetingCardRemovedFromCart\") {\n      key\n      value\n    }\n  }\n": types.IncrementStatisticGreetingCardRemovedFromCartDocument,
    "\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      autoCoupon\n      cardMaxRating\n      productId\n      disableGiftCards\n      giftCardBlacklist\n    }\n  }\n": types.KioskDocument,
    "\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      disableGiftCards\n      autoCoupon\n      giftCardBlacklist\n    }\n  }\n": types.KiosksDocument,
    "\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": types.MobileOrderPickupCodeDocument,
    "\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.AdminOrderDetailsDocument,
    "\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      chargeCaptured\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      selfOrder\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      paymentIntentId\n      status\n      shippingName\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.OrderDetailsDocument,
    "\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n": types.OrderMobilePickupDocument,
    "\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n      couponId\n      customerAccountId\n    }\n  }\n": types.OrdersDocument,
    "\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n": types.CardPrintQualityImageDocument,
    "\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      selfOrder\n      shippingName\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n": types.QueryShippingOrdersDocument,
    "\n  query Statistics {\n    statistics {\n      id\n      key\n      createdAt\n      updatedAt\n      value\n    }\n  }\n": types.StatisticsDocument,
    "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n": types.OrderUpdateDocument,
    "\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n": types.UserSubscribeNewsletterDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountCoupons {\n    accountCoupons {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"): (typeof documents)["\n  query AccountCoupons {\n    accountCoupons {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertCreate($input: AdvertCreateInput!) {\n    advertCreate(input: $input) {\n      id\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertDelete($advertDeleteId: ID!) {\n    advertDelete(id: $advertDeleteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation AdvertUpdate($input: AdvertUpdateInput!) {\n    advertUpdate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardCreate($input: CardCreateInput!) {\n    cardCreate(input: $input) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardDelete($cardDeleteId: ID!) {\n    cardDelete(id: $cardDeleteId) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n"): (typeof documents)["\n  mutation CardImagesUploadURL($cardId: ID!) {\n    cardImagesUploadURL(cardId: $cardId) {\n      coverImageUploadURL\n      interiorImageUploadURL\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n"): (typeof documents)["\n  mutation CardUpdate($input: CardUpdateInput!) {\n    cardUpdate(input: $input) {\n      id\n      cardName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCards($input: CardsInput) {\n    cards(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      sku\n      rating\n      ordering\n      categories {\n        id\n        categoryName\n      }\n      designer {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryCreate($input: CategoryCreateInput!) {\n    categoryCreate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryDelete($id: ID!) {\n    categoryDelete(id: $id) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  mutation CategoryUpdate($input: CategoryUpdateInput!) {\n    categoryUpdate(input: $input) {\n      id\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponCreate($input: CouponCreateInput) {\n    couponCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponDelete($couponDeleteId: ID!) {\n    couponDelete(id: $couponDeleteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n"): (typeof documents)["\n  mutation CouponUpdate($input: CouponUpdateInput) {\n    couponUpdate(input: $input) {\n      id\n      isActive\n      redemptions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n"): (typeof documents)["\n  mutation AdminCreate($input: AdminCreateInput!) {\n    adminCreate(input: $input) {\n      id\n      firstName\n      lastName\n      email\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GiftCardVendorCreate($input: GiftCardVendorCreateInput!) {\n    giftCardVendorCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation GiftCardVendorCreate($input: GiftCardVendorCreateInput!) {\n    giftCardVendorCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GiftCardVendorDelete($giftCardVendorDeleteId: String!) {\n    giftCardVendorDelete(id: $giftCardVendorDeleteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation GiftCardVendorDelete($giftCardVendorDeleteId: String!) {\n    giftCardVendorDelete(id: $giftCardVendorDeleteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GiftCardVendorUpdate($input: GiftCardVendorUpdateInput!) {\n    giftCardVendorUpdate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation GiftCardVendorUpdate($input: GiftCardVendorUpdateInput!) {\n    giftCardVendorUpdate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation KioskCreate($input: KioskCreateInput!) {\n    kioskCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation KioskUpdate($input: KioskUpdateInput!) {\n    kioskUpdate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation KioskUpdateAll($input: KioskUpdateAllInput!) {\n    kioskUpdateAll(input: $input)\n  }\n"): (typeof documents)["\n  mutation KioskUpdateAll($input: KioskUpdateAllInput!) {\n    kioskUpdateAll(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n"): (typeof documents)["\n  mutation AdminLogin($input: AdminLoginInput!) {\n    adminLogin(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        email\n        role\n      }\n      token\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderRefreshPickup($orderRefreshPickupId: ID!) {\n    orderRefreshPickup(id: $orderRefreshPickupId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation OrderRefreshPickup($orderRefreshPickupId: ID!) {\n    orderRefreshPickup(id: $orderRefreshPickupId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminUserWhitelistCreate($input: AdminUserWhitelistCreateInput!) {\n    adminUserWhitelistCreate(input: $input) {\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUserWhitelistCreate($input: AdminUserWhitelistCreateInput!) {\n    adminUserWhitelistCreate(input: $input) {\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AdminUserWhitelistDelete($email: String!) {\n    adminUserWhitelistDelete(email: $email) {\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUserWhitelistDelete($email: String!) {\n    adminUserWhitelistDelete(email: $email) {\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminUserWhitelists {\n    adminUserWhitelists {\n      email\n      role\n      isRegistered\n    }\n  }\n"): (typeof documents)["\n  query AdminUserWhitelists {\n    adminUserWhitelists {\n      email\n      role\n      isRegistered\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  query Advert($advertId: ID!) {\n    advert(id: $advertId) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  query AdvertByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"): (typeof documents)["\n  query Adverts($input: AdvertsInput!) {\n    adverts(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"): (typeof documents)["\n  query AdvertsByKioskAndCategories($input: AdvertsByKioskAndCategoriesInput!) {\n    advertsByKioskAndCategories(input: $input) {\n      id\n      kioskId\n      categoryId\n      isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Categories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CategoriesWithRatingFilter(\n    $input: CategoriesInput!\n    $maxRating: Int\n    $cardsAfterDarkEnabled: Boolean\n    $spanish: Boolean\n  ) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      featured\n      seasonal\n      parent {\n        id\n        categoryName\n      }\n      cards(\n        maxRating: $maxRating\n        cardsAfterDarkEnabled: $cardsAfterDarkEnabled\n        spanish: $spanish\n      ) {\n        id\n        cardName\n        isActive\n        sku\n        images {\n          id\n          mainImageDescription\n          previewMainPhotoUrl\n          previewInteriorPhotoUrl\n        }\n        categories {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n      cards(maxRating: 4) {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query AddonCategories($input: CategoriesInput!) {\n    categories(input: $input) {\n      id\n      categoryName\n      categoryNameSpanish\n      parent {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query Card($cardId: ID!) {\n    card(id: $cardId) {\n      id\n      cardName\n      isActive\n      sku\n      rating\n      ordering\n      spanish\n      designer {\n        id\n        name\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n      categories {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  query CardsByCategories($input: CardsByCategoriesInput!) {\n    cardsByCategories(input: $input) {\n      id\n      isActive\n      cardName\n      categories {\n        id\n        categoryName\n      }\n      images {\n        id\n        mainImageDescription\n        previewMainPhotoUrl\n        previewInteriorPhotoUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n"): (typeof documents)["\n  query Category($categoryId: ID!) {\n    category(categoryId: $categoryId) {\n      id\n      categoryName\n      categoryNameSpanish\n      categoryType\n      parent {\n        id\n        categoryName\n      }\n      featured\n      seasonal\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"): (typeof documents)["\n  query Coupon($couponId: ID!) {\n    coupon(id: $couponId) {\n      id\n      isActive\n      couponAmount\n      productId\n      expirationDate\n      maxRedemptions\n      minimumAmount\n      redemptions\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CouponRedemptionsIncrement($couponRedemptionsIncrementId: ID!) {\n    couponRedemptionsIncrement(id: $couponRedemptionsIncrementId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"): (typeof documents)["\n  query Coupons($input: CouponsInput) {\n    coupons(input: $input) {\n      id\n      productId\n      isActive\n      expirationDate\n      couponAmount\n      maxRedemptions\n      redemptions\n      minimumAmount\n      rewardsPointsCost\n      greetingCardCount\n      giftCardAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  mutation OrderCreate($input: OrderCreateInput!) {\n    orderCreate(input: $input) {\n      id\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n"): (typeof documents)["\n  mutation OrderReceiptCreate($input: OrderReceiptCreateInput!) {\n    orderReceiptCreate(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation PersonalizedCardCreate($input: PersonalizedCardCreateInput!) {\n    personalizedCardCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation PersonalizedCardsCreate($input: [PersonalizedCardCreateInput!]!) {\n    personalizedCardsCreate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerAccount($input: ID!) {\n    customerAccount(id: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n      Order {\n        id\n        totalPrice\n        couponId\n        status\n        orderPlacedFrom\n        orderStartedTimestamp\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomerAccount($input: ID!) {\n    customerAccount(id: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n      Order {\n        id\n        totalPrice\n        couponId\n        status\n        orderPlacedFrom\n        orderStartedTimestamp\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CustomerAccountAwardPoints($input: AwardPointsInput!) {\n    customerAccountAwardPoints(input: $input) {\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  mutation CustomerAccountAwardPoints($input: AwardPointsInput!) {\n    customerAccountAwardPoints(input: $input) {\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerAccountByEmail($email: String!) {\n    customerAccountByEmail(email: $email) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      isCognitoVerified\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  query CustomerAccountByEmail($email: String!) {\n    customerAccountByEmail(email: $email) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      isCognitoVerified\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerAccountByPhoneNumber($phoneNumber: String!) {\n    customerAccountByPhoneNumber(phoneNumber: $phoneNumber) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedPhone\n      isSubscribedEmail\n      isCognitoVerified\n      isKioskVerified\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  query CustomerAccountByPhoneNumber($phoneNumber: String!) {\n    customerAccountByPhoneNumber(phoneNumber: $phoneNumber) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedPhone\n      isSubscribedEmail\n      isCognitoVerified\n      isKioskVerified\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CustomerAccountCreate($input: CreateCustomerAccountInput!) {\n    customerAccountCreate(input: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  mutation CustomerAccountCreate($input: CreateCustomerAccountInput!) {\n    customerAccountCreate(input: $input) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation customerAccountDeactivate($input: CustomerAccountDeactivateInput!) {\n    customerAccountDeactivate(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation customerAccountDeactivate($input: CustomerAccountDeactivateInput!) {\n    customerAccountDeactivate(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CustomerAccountRedeemPoints($input: RedeemPointsInput!) {\n    customerAccountRedeemPoints(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CustomerAccountRedeemPoints($input: RedeemPointsInput!) {\n    customerAccountRedeemPoints(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CustomerAccountUpdate($id: ID!, $edits: EditCustomerAccountInput!) {\n    customerAccountUpdate(id: $id, edits: $edits) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  mutation CustomerAccountUpdate($id: ID!, $edits: EditCustomerAccountInput!) {\n    customerAccountUpdate(id: $id, edits: $edits) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerAccounts($pagination: CustomerAccountInput) {\n    customerAccounts(pagination: $pagination) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"): (typeof documents)["\n  query CustomerAccounts($pagination: CustomerAccountInput) {\n    customerAccounts(pagination: $pagination) {\n      id\n      createdAt\n      updatedAt\n      email\n      phoneNumber\n      firstName\n      lastName\n      isSubscribedEmail\n      isSubscribedPhone\n      rewardPoints\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomerCount {\n    customerCount\n  }\n"): (typeof documents)["\n  query CustomerCount {\n    customerCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CustomerLogin($input: CustomerLoginInput!) {\n    customerLogin(input: $input) {\n      customer {\n        phoneNumber\n        email\n        firstName\n        id\n        isSubscribedEmail\n        isSubscribedPhone\n        isCognitoVerified\n        lastName\n        updatedAt\n        rewardPoints\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CustomerLogin($input: CustomerLoginInput!) {\n    customerLogin(input: $input) {\n      customer {\n        phoneNumber\n        email\n        firstName\n        id\n        isSubscribedEmail\n        isSubscribedPhone\n        isCognitoVerified\n        lastName\n        updatedAt\n        rewardPoints\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReturningCustomerCount {\n    returningCustomerCount\n  }\n"): (typeof documents)["\n  query ReturningCustomerCount {\n    returningCustomerCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrderForPickup($orderId: ID!) {\n    order(id: $orderId) {\n      status\n      orderDetails {\n        id\n        orderId\n        card {\n          id\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            faceplatePath\n            id\n            name\n          }\n        }\n        signature\n        signatureFont\n        customMessage\n        quantity\n        price\n        textFormat\n        textPosition\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GiftCard($giftCardId: String!) {\n    giftCard(id: $giftCardId) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GiftCard($giftCardId: String!) {\n    giftCard(id: $giftCardId) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GiftCardCategories {\n    giftCardCategories {\n      id\n      categoryNameSpanish\n      categoryName\n    }\n  }\n"): (typeof documents)["\n  query GiftCardCategories {\n    giftCardCategories {\n      id\n      categoryNameSpanish\n      categoryName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GiftCards($input: GiftCardsInput!) {\n    giftCards(input: $input) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GiftCards($input: GiftCardsInput!) {\n    giftCards(input: $input) {\n      denominationType\n      id\n      isActive\n      name\n      denominations\n      faceplatePath\n      favoriteRank\n      provider\n      cardMaxRating\n      categories {\n        id\n        categoryName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation IncrementStatisticGiftCardRemovedFromOrder {\n    incrementStatistic(key: \"giftCardRemovedFromOrder\") {\n      key\n      value\n    }\n  }\n"): (typeof documents)["\n  mutation IncrementStatisticGiftCardRemovedFromOrder {\n    incrementStatistic(key: \"giftCardRemovedFromOrder\") {\n      key\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation IncrementStatisticStillThereIdleTimeout {\n    incrementStatistic(key: \"stillThereIdleTimeout\") {\n      key\n      value\n    }\n  }\n"): (typeof documents)["\n  mutation IncrementStatisticStillThereIdleTimeout {\n    incrementStatistic(key: \"stillThereIdleTimeout\") {\n      key\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation IncrementStatisticGreetingCardRemovedFromCart {\n    incrementStatistic(key: \"greetingCardRemovedFromCart\") {\n      key\n      value\n    }\n  }\n"): (typeof documents)["\n  mutation IncrementStatisticGreetingCardRemovedFromCart {\n    incrementStatistic(key: \"greetingCardRemovedFromCart\") {\n      key\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      autoCoupon\n      cardMaxRating\n      productId\n      disableGiftCards\n      giftCardBlacklist\n    }\n  }\n"): (typeof documents)["\n  query Kiosk($kioskId: ID!) {\n    kiosk(id: $kioskId) {\n      id\n      isActive\n      taxRate\n      maintenanceScreen\n      printIncludeLogo\n      tablet\n      terminalId\n      terminalLocationId\n      autoCoupon\n      cardMaxRating\n      productId\n      disableGiftCards\n      giftCardBlacklist\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      disableGiftCards\n      autoCoupon\n      giftCardBlacklist\n    }\n  }\n"): (typeof documents)["\n  query Kiosks($input: KiosksInput) {\n    kiosks(input: $input) {\n      id\n      isActive\n      cardMaxRating\n      maintenanceScreen\n      disableGiftCards\n      autoCoupon\n      giftCardBlacklist\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  query MobileOrderPickupCode($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminOrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      user {\n        id\n        firstName\n        lastName\n        email\n      }\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      status\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      chargeCaptured\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      selfOrder\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      paymentIntentId\n      status\n      shippingName\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrderDetails($orderId: ID!) {\n    order(id: $orderId) {\n      id\n      chargeCaptured\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      pickupCode\n      state\n      selfOrder\n      totalPrice\n      orderPlacedFrom\n      orderStartedTimestamp\n      paymentAttemptedTimestamp\n      paymentCompletedTimestamp\n      orderCompletedTimestamp\n      paymentIntentId\n      status\n      shippingName\n      userEmail\n      orderDetails {\n        price\n        quantity\n        card {\n          id\n          cardName\n          isActive\n          sku\n          images {\n            previewMainPhotoUrl\n            mainImageDescription\n          }\n        }\n        giftCardTransaction {\n          denomination\n          vendor {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query OrderMobilePickup($pickupCode: String!) {\n    orderMobilePickup(pickupCode: $pickupCode) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n      couponId\n      customerAccountId\n    }\n  }\n"): (typeof documents)["\n  query Orders($input: OrdersInput) {\n    orders(input: $input) {\n      id\n      status\n      pickupKioskId\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n      couponId\n      customerAccountId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n"): (typeof documents)["\n  query CardPrintQualityImage($cardPrintQualityImageId: ID!) {\n    cardPrintQualityImage(id: $cardPrintQualityImageId) {\n      main\n      interior\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      selfOrder\n      shippingName\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query queryShippingOrders($input: OrdersInput) {\n    orders(input: $input) {\n      city\n      country\n      line1\n      line2\n      name\n      postalCode\n      state\n      id\n      selfOrder\n      shippingName\n      status\n      pickupKioskId\n      pickupCode\n      orderPlacedFrom\n      orderStartedTimestamp\n      orderCompletedTimestamp\n      totalPrice\n      userEmail\n      user {\n        email\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Statistics {\n    statistics {\n      id\n      key\n      createdAt\n      updatedAt\n      value\n    }\n  }\n"): (typeof documents)["\n  query Statistics {\n    statistics {\n      id\n      key\n      createdAt\n      updatedAt\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"): (typeof documents)["\n  mutation OrderUpdate($input: OrderUpdateInput!) {\n    orderUpdate(input: $input) {\n      id\n      pickupCode\n      totalPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n"): (typeof documents)["\n  mutation UserSubscribeNewsletter($email: String!) {\n    userSubscribeNewsletter(email: $email) {\n      id\n      isSubscribed\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;