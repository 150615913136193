import { graphql } from '../generated-gql';

export const queryCoupon = graphql(`
  query Coupon($couponId: ID!) {
    coupon(id: $couponId) {
      id
      isActive
      couponAmount
      productId
      expirationDate
      maxRedemptions
      minimumAmount
      redemptions
      rewardsPointsCost
      greetingCardCount
      giftCardAmount
    }
  }
`);
