import { graphql } from '../generated-gql';

export const mutationCustomerLogin = graphql(`
  mutation CustomerLogin($input: CustomerLoginInput!) {
    customerLogin(input: $input) {
      customer {
        phoneNumber
        email
        firstName
        id
        isSubscribedEmail
        isSubscribedPhone
        isCognitoVerified
        lastName
        updatedAt
        rewardPoints
        createdAt
      }
    }
  }
`);
