import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import Link from 'next/link';
import { useSession, signOut } from 'next-auth/react';
import { cx } from 'cva';
import { Badge, SVG, CADConfirmationModal } from '@inkd/ui'; // <-- Import the modal here
import { useCartState } from '@web/utils';
import { CardsAfterDarkContext } from '@web/context/cardsAfterDark';
import { FlagToggleContext } from '@web/context/flagToggle';

// Define all translations outside the component
const translations = {
  en: {
    signIn: 'Sign In',
    dashboard: 'Dashboard',
    logOut: 'Log Out',
    switchLanguage: 'Cambiar a Español', // Switch to Spanish
    categories: 'All Categories',
    personalization: 'Cancel Personalization',
    cart: 'Cart',
    helpInfo: 'Help & Info',
    kioskLocations: 'Kiosk locations',
    needSupport: 'Need Support?',
    privacyPolicyTerms: 'Privacy Policy and Terms of Service',
    language: 'Language',
    cancelPersonalizationAria: 'Cancel personalization of card',
    cardsAfterDark: 'Cards After Dark',
    switchToRegular: 'Switch to Regular',
  },
  es: {
    signIn: 'Iniciar Sesión',
    dashboard: 'Panel de Control',
    logOut: 'Cerrar Sesión',
    switchLanguage: 'Switch to English',
    categories: 'Todas las Categorías',
    personalization: 'Cancelar Personalización',
    cart: 'Carrito',
    helpInfo: 'Ayuda e Información',
    kioskLocations: 'Ver ubicaciones',
    needSupport: '¿Necesitas ayuda?',
    privacyPolicyTerms: 'Política de Privacidad y Términos del Servicio',
    language: 'Idioma',
    cancelPersonalizationAria: 'Cancelar personalización de la tarjeta',
    cardsAfterDark: 'Tarjetas Traviesas',
    switchToRegular: 'Cambiar a Regular',
  },
};

interface BrowsingFlowNavbarProps {
  flow: 'browsing';
  handleCancelPersonalization?: never;
}
interface PersonalizationFlowNavbarProps {
  flow: 'personalization';
  handleCancelPersonalization: () => void;
}
interface CheckoutFlowNavbarProps {
  flow: 'checkout';
  handleCancelPersonalization?: never;
}
interface AccountFlowNavbarProps {
  flow: 'account';
  handleCancelPersonalization?: never;
}

type NavbarProps =
  | BrowsingFlowNavbarProps
  | PersonalizationFlowNavbarProps
  | CheckoutFlowNavbarProps
  | AccountFlowNavbarProps;

export function Navbar({ flow, handleCancelPersonalization }: NavbarProps) {
  const cardsAfterDarkContext = useContext(CardsAfterDarkContext);
  const languageContext = useContext(FlagToggleContext);
  const { status } = useSession();
  const isLoggedIn = status === 'authenticated';

  // Track the hamburger menu state (left side)
  const [menuOpen, setMenuOpen] = useState(false);

  const [isCADModalOpen, setIsCADModalOpen] = useState(false);

  const isCardsAfterDark = cardsAfterDarkContext?.isCardsAfterDark ?? false;
  const isFlagToggle = languageContext?.isFlagToggle ?? false;

  const handleCADToggle = useCallback(() => {
    if (!isCardsAfterDark) {
      setIsCADModalOpen(true);
    } else {
      cardsAfterDarkContext?.setCardsAfterDark(false);
    }
  }, [isCardsAfterDark, cardsAfterDarkContext]);

  const handleCADModalConfirm = useCallback(() => {
    cardsAfterDarkContext?.setCardsAfterDark(true);
    setIsCADModalOpen(false);
  }, [cardsAfterDarkContext]);

  const handleCADModalCancel = useCallback(() => {
    setIsCADModalOpen(false);
  }, []);

  // Toggle function for the language (flag toggle)
  const toggleLanguage = useCallback(() => {
    if (
      languageContext &&
      typeof languageContext.setFlagToggle === 'function'
    ) {
      languageContext.setFlagToggle(!languageContext.isFlagToggle);
    }
  }, [languageContext]);

  // Determine the current language
  const currentLang = isFlagToggle ? 'es' : 'en';

  // Determine background gradient based on flow or Cards After Dark
  const flowBackgrounds: Record<NavbarProps['flow'], string> = {
    browsing: 'bg-gradient-blue-to-yellow',
    personalization: 'bg-gradient-blue-to-purple',
    checkout: 'bg-gradient-lightblue-to-blue',
    account: 'bg-gradient-blue-to-yellow',
  };
  const navBackground = isCardsAfterDark
    ? 'bg-gradient-blue-to-purple'
    : flowBackgrounds[flow];

  // Refs for the left-hamburger dropdown
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    }

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <nav
        aria-label='Main navigation'
        className={cx('sticky top-0 z-50', navBackground)}
      >
        <div className='relative mx-auto flex max-w-screen-md items-center justify-between p-3'>
          {/* Left Section: Logo + left hamburger */}
          <div className='flex items-center space-x-2'>
            <NavbarLogo
              flow={flow}
              handleCancelPersonalization={handleCancelPersonalization}
            />

            <button
              type='button'
              onClick={() => setMenuOpen(prev => !prev)}
              ref={buttonRef}
              className='
                inline-flex 
                h-12 w-12
                items-center justify-center 
                rounded-lg bg-white p-2 
                text-sm text-gray-500
                hover:bg-gray-100
                focus:outline-none focus:ring-2 focus:ring-gray-200
              '
              aria-label='Open menu'
            >
              <svg
                className='h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 17 14'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M1 1h15M1 7h15M1 13h15'
                />
              </svg>
            </button>
          </div>

          {flow !== 'account' && (
            <NavbarToggles
              flow={flow}
              isCardsAfterDark={isCardsAfterDark}
              isFlagToggle={isFlagToggle}
              onCardsAfterDarkToggle={handleCADToggle}
              currentLang={currentLang}
            />
          )}

          {/* Right Section => user icon + cart or personalization cancel */}
          <NavbarActions
            flow={flow}
            handleCancelPersonalization={handleCancelPersonalization}
            currentLang={currentLang}
          />

          {/* Left Hamburger Dropdown */}
          {menuOpen && (
            <div
              ref={dropdownRef}
              className='
                absolute left-0 top-16
                z-50 mt-2
                flex w-48
                flex-col
                space-y-2
                rounded-md
                bg-white
                p-2
                shadow-md
              '
            >
              {/* Group 1: Account */}
              <div>
                {!isLoggedIn ? (
                  <Link href='/account/sign-in'>
                    {translations[currentLang].signIn}
                  </Link>
                ) : (
                  <>
                    <Link href='/account/dashboard'>
                      {translations[currentLang].dashboard}
                    </Link>
                  </>
                )}
              </div>

              {/* Group 2: Help & Info */}
              <div className='border-t border-gray-200 pt-2'>
                <p className='mb-1 text-xs font-semibold uppercase text-gray-500'>
                  {translations[currentLang].helpInfo}
                </p>
                <a
                  href='http://www.inkdgreetings.com/locations'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='block text-sm hover:text-gray-600'
                >
                  {translations[currentLang].kioskLocations}
                </a>
                <a
                  href='https://www.inkdgreetings.com/contact'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='block text-sm hover:text-gray-600'
                >
                  {translations[currentLang].needSupport}
                </a>
                <a
                  href='https://www.inkdgreetings.com/terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='block text-sm hover:text-gray-600'
                >
                  {translations[currentLang].privacyPolicyTerms}
                </a>
              </div>

              {/* Language Toggle */}
              <div className='border-t border-gray-200 pt-2'>
                <p className='mb-1 text-xs font-semibold uppercase text-gray-500'>
                  {translations[currentLang].language}
                </p>
                <button
                  onClick={toggleLanguage}
                  className='
                    flex w-full items-center
                    justify-between
                    text-left text-sm
                    hover:text-gray-600
                  '
                >
                  <span>{translations[currentLang].switchLanguage}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
      {isCADModalOpen && (
        <CADConfirmationModal
          version='web'
          handleConfirm={handleCADModalConfirm}
          handleCancel={handleCADModalCancel}
          spanish={currentLang === 'es'}
        />
      )}
    </>
  );
}

/** Logo or Cancel Button */
function NavbarLogo({
  flow,
  handleCancelPersonalization,
}: {
  flow: NavbarProps['flow'];
  handleCancelPersonalization?: () => void;
}) {
  if (flow === 'personalization' && handleCancelPersonalization) {
    return (
      <button onClick={handleCancelPersonalization}>
        <SVG name='Logo' className='h-16 w-10' />
      </button>
    );
  }

  return (
    <Link href='/' aria-label='Home screen'>
      <SVG name='Logo' className='h-16 w-10' />
    </Link>
  );
}

interface NavbarTogglesProps {
  flow: NavbarProps['flow'];
  isCardsAfterDark: boolean;
  isFlagToggle: boolean;
  onCardsAfterDarkToggle: () => void;
  currentLang: 'en' | 'es';
}

/** Cards After Dark Toggle */
function NavbarToggles({
  isCardsAfterDark,
  onCardsAfterDarkToggle,
  currentLang,
}: NavbarTogglesProps) {
  const labelText = isCardsAfterDark
    ? translations[currentLang].switchToRegular
    : translations[currentLang].cardsAfterDark;

  return (
    <div
      className='
        flex flex-col 
        items-center
        space-y-1
        sm:flex-row sm:space-x-2
        sm:space-y-0
      '
    >
      <p
        className='text-[13px] md:text-[16px]'
        style={{
          fontFamily: "LazyDog, 'Courier New', Courier, monospace",
        }}
      >
        {labelText}
      </p>
      <label className='switch small' aria-label='Toggle Cards After Dark mode'>
        <input
          type='checkbox'
          checked={isCardsAfterDark}
          onChange={onCardsAfterDarkToggle}
        />
        <span className='slider' />
      </label>
    </div>
  );
}

interface NavbarActionsProps {
  flow: NavbarProps['flow'];
  handleCancelPersonalization?: () => void;
  currentLang: 'en' | 'es';
}

/** Right side: user icon first, then cart or personalization cancel */
function NavbarActions({
  flow,
  handleCancelPersonalization,
  currentLang,
}: NavbarActionsProps) {
  return (
    <div className='flex items-center space-x-4'>
      {/* Always show the User Icon first */}
      <UserMenu currentLang={currentLang} />

      {/* Then show browsing or personalization actions */}
      {flow === 'browsing' && <BrowsingFlow currentLang={currentLang} />}
      {flow === 'personalization' && handleCancelPersonalization && (
        <button
          className='flex items-center justify-center rounded-full bg-white p-2 sm:p-1'
          aria-label={translations[currentLang].cancelPersonalizationAria}
          onClick={handleCancelPersonalization}
        >
          <SVG name='X' className='h-8 w-8' />
        </button>
      )}
    </div>
  );
}

interface BrowsingFlowProps {
  currentLang: 'en' | 'es';
}

/** Cart icon (browsing flow) */
function BrowsingFlow({ currentLang }: BrowsingFlowProps) {
  const { cart } = useCartState();
  const cartQuantity = useMemo(() => cart.length, [cart]);

  return (
    <Link
      href='/checkout/cart'
      className='relative flex items-center justify-center rounded-full bg-white p-2'
    >
      <span className='sr-only'>{translations[currentLang].cart}</span>
      <SVG name='Cart' className='h-8 w-8' />
      {cartQuantity > 0 && (
        <Badge
          aria-label={`${cartQuantity} item${
            cartQuantity === 1 ? '' : 's'
          } in cart`}
          className='absolute -right-1 -top-1'
        >
          {cartQuantity}
        </Badge>
      )}
    </Link>
  );
}

interface UserMenuProps {
  currentLang: 'en' | 'es';
}

/** The user icon on the right side: toggles a small dropdown */
function UserMenu({ currentLang }: UserMenuProps) {
  const [open, setOpen] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);
  const { status } = useSession();
  const isLoggedIn = status === 'authenticated';

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropRef.current && !dropRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div className='relative' ref={dropRef}>
      <button
        type='button'
        onClick={() => setOpen(o => !o)}
        aria-label='Open user menu'
        className='
          inline-flex 
          h-12 w-12
          items-center justify-center 
          rounded-lg bg-white p-2 
          text-sm text-gray-500
          hover:bg-gray-100
          focus:outline-none focus:ring-2 focus:ring-gray-200
        '
      >
        <SVG name='User Icon' className='h-8 w-8' />
      </button>

      {open && (
        <div
          className='
            absolute right-0 top-full
            z-50 mt-2
            flex w-36
            flex-col
            space-y-1
            rounded-md bg-white
            p-2
            shadow-md
          '
        >
          {!isLoggedIn ? (
            <Link href='/account/sign-in'>
              {translations[currentLang].signIn}
            </Link>
          ) : (
            <>
              <Link href='/account/dashboard'>
                {translations[currentLang].dashboard}
              </Link>
              <button
                type='button'
                onClick={() => signOut({ callbackUrl: '/' })}
                className='text-left'
              >
                {translations[currentLang].logOut}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
