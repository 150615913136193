import { graphql } from '../generated-gql';

export const queryAccountCoupons = graphql(`
  query AccountCoupons {
    accountCoupons {
      id
      productId
      isActive
      expirationDate
      couponAmount
      maxRedemptions
      redemptions
      minimumAmount
      rewardsPointsCost
      greetingCardCount
      giftCardAmount
    }
  }
`);
